import Modal from "./Modal";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import { useEffect, useState } from "react";
import { IconButton } from "./IconButton";
import { faCartPlus, faLock } from "@fortawesome/free-solid-svg-icons";
import { ethers } from "ethers";
import { buyBlacxes, getBlacxesPrice, getFreeBlacxesBalance, getLockedBlacxesBalance, getTotalBlacxes, lockBlacxes } from "../ContractsInteraction/blacxes";
import { formatNumber } from "../ContractsInteraction/utils/utils";

const minDate = new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000);
const operationEnum = Object.freeze({
    none: 0,
    lock: 1,
    buy: 2,
    buyPackage: 3
});

const BlacxesModal = ({ isOpen, onClose }) => {

    const [lockTime, setLockTime] = useState(minDate);
    const [processing, setProcessing] = useState(false);
    const [lockAmount, setLockAmount] = useState(0);
    const [buyAmount, setBuyAmount] = useState(0);
    const [blacxesBalance, setBlacxesBalance] = useState(0);
    const [lockedBlacxesBalance, setLockedBlacxesBalance] = useState(0);
    const [freeBlacxesBalance, setFreeBlacxesBalance] = useState(0);
    const [blacxesPrice, setBlacxesPrice] = useState(0);
    const [totalBlacxesPrice, setTotalBlacxesPrice] = useState(0);
    const [operation, setOperation] = useState(operationEnum.none);

    const fetchBalances = async () => {
        setBlacxesPrice(ethers.formatUnits(await getBlacxesPrice()));
        setLockedBlacxesBalance(await getLockedBlacxesBalance());
        setBlacxesBalance(await getTotalBlacxes());
        setFreeBlacxesBalance(await getFreeBlacxesBalance());
    };

    useEffect(() => {
        fetchBalances();
    }, []);

    useEffect(() => {
        setTotalBlacxesPrice(blacxesPrice * parseInt(buyAmount));
    }, [buyAmount, blacxesPrice]);

    return (
        <Modal title='Blacxes Store' isOpen={isOpen} onClose={onClose}>
            <h3>Blacxes Balance: {formatNumber(blacxesBalance, 0)}</h3>
            <h3>Locked Blacxes Balance: {formatNumber(lockedBlacxesBalance, 0)}</h3>
            <h3>Free Blacxes Balance: {formatNumber(freeBlacxesBalance, 0)}</h3>
            <h3>One Blacxes Price: {formatNumber(blacxesPrice, 6)} ACCES</h3>

            <span style={{display:'block', width:'100%', textAlign: 'center' }}>
                <IconButton icon={faLock} onClick={() => setOperation(operationEnum.lock)} />
                <IconButton icon={faCartPlus} onClick={() => setOperation(operationEnum.buy)} />
            </span>

            {operation === operationEnum.lock && (
                <>
                    <input
                        type="text"
                        placeholder="Enter amount you want to lock."
                        value={lockAmount}
                        disabled={processing}
                        onChange={(e) => setLockAmount(e.target.value)}
                    />
                    <DatePicker
                        selected={lockTime}
                        minDate={minDate}
                        onChange={(date) => setLockTime(date)}
                        dateFormat="yyyy-MM-dd" // Format the date as needed
                        placeholderText="Select Lock Time"
                        className="custom-datepicker"
                    />
                    <button
                        disabled={!lockTime || !lockAmount || processing}
                        onClick={async () => {
                            setProcessing(true);
                            await lockBlacxes(lockAmount, new Date(lockTime).getTime());
                            await fetchBalances();
                            setProcessing(false);
                        }
                        }>
                        Lock
                    </button>
                </>
            )}
            {operation === operationEnum.buy && (
                <>
                    <h3>Total Price: {formatNumber(totalBlacxesPrice, 6)} ACCES</h3>
                    <input
                        type="text"
                        placeholder="Enter amount you want to buy."
                        value={buyAmount}
                        disabled={processing}
                        onChange={(e) => setBuyAmount(e.target.value)}
                    />
                    <button
                        disabled={!buyAmount || processing}
                        onClick={async () => {
                            setProcessing(true);
                            await buyBlacxes(buyAmount);
                            await fetchBalances();
                            setProcessing(false);
                        }
                        }>
                        Buy
                    </button>
                </>
            )}
        </Modal>
    );
};

export default BlacxesModal;
