import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as dataManager from '../ContractsInteraction/dataManager.js';
import { faCopy, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard, getShortString } from '../ContractsInteraction/utils/utils.js';
import { IconButton } from '../Components/IconButton.js';
import { loggedIn } from '../ContractsInteraction/account.js';
import { getUserId } from '../ContractsInteraction/profile.js';

function DataManager() {
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [offset, setOffset] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [rank, setRank] = useState(-1);
    const limit = 50; // Set limit to 50 for incremental loading
    const navigate = useNavigate();

    // Get Users Data
    useEffect(() => {
        const getData = async () => {
            if (loading || !hasMore) return;

            setLoading(true);
            try {
                const { users: newUsers } = await dataManager.getPaginatedUsersData(offset, limit);
                
                if (newUsers.length < limit) {
                    setHasMore(false); // Stop further requests if returned users are less than limit
                }
                
                setUsers((prevUsers) => [...prevUsers, ...newUsers].sort((a, b) => parseInt(b.totalXP) - parseInt(a.totalXP)));
                setOffset((prevOffset) => prevOffset + limit); // Increment offset for the next batch
            } catch (err) {
                console.error('Failed to get users', err);
            } finally {
                setLoading(false);
            }
        };

        getData();
    }, [offset, hasMore, loading]);

    useEffect(() => {
        const setRankAsync = async () => {
            const userId = await getUserId();
            setRank(users.findIndex((user) => user.userId.toString() === userId.toString()) + 1);
        };
        
        if (loggedIn()) {
            setRankAsync();
        }
    }, [users]);

    return (
        <div className="user-profile">
            <div className="header">
                <h1>{rank === -1 ? 'Access Users' : 'Leaderboard'}</h1>
                {rank !== -1 && (<h3>Your Rank: {rank} of {users.length} total users.</h3>)}
                <button onClick={() => navigate('/')}>Back to Home</button>
            </div>
            <div className="table-container">
                <table className="profile-table">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>User ID</th>
                            <th>User Name</th>
                            <th>Total XP</th>
                            <th>Referral XP</th>
                            <th>Bio Text</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user, index) => (
                            <tr key={index} style={rank === index + 1 ? { backgroundColor: 'rgba(0, 255, 0, 0.5)' } : {}}>
                                <td className="label" style={rank === index + 1 ? { backgroundColor: 'rgba(0, 255, 0, 0.5)' } : {}}>{index + 1}</td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(user.userId)} />
                                    <IconButton icon={faExternalLink} onClick={() => window.open(`${window.location.origin}/profile?userId=${user.userId}`, '_blank')} />
                                    {getShortString(user.userId)}
                                </td>
                                <td className="value">{user.userName}</td>
                                <td className="value">{user.totalXP.toString()}</td>
                                <td className="value">{user.referralXP.toString()}</td>
                                <td className="value">{user.bioText}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default DataManager;
