import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { createUser, importUser, loggedIn } from '../ContractsInteraction/account.js';
import { Loading } from '../Components/Loading';

const CreateImportAccount = () => {
    const [isCreating, setIsCreating] = useState(true);
    const [username, setUsername] = useState('');
    const [gender, setGender] = useState({ value: 'male', label: 'Male' });
    const [secret, setSecret] = useState('');
    const [loading, setLoading] = useState(true);
    const [referrer, setReferrer] = useState(null);
    const [result, setResult] = useState(null);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const genderOptions = [
        { value: 'male', label: 'Male' },
        { value: 'female', label: 'Female' }
    ];

    // Get Referrer from URL
    useEffect(() => {
        const refParam = new URLSearchParams(window.location.search).get('ref');
        if (refParam) {
            setReferrer(refParam);
        }
    }, []);

    // Handle Result Status
    useEffect(() => {
        if ((result && result.status) || loggedIn()) {
            navigate('/');
        } else {
            setError(result?.error);
            setLoading(false);
        }
    }, [result, navigate]);

    // Create Accoount
    const handleCreate = async () => {
        setLoading(true);
        const genderValue = gender.value === 'male' ? 1 : 2; // Male is 1, Female is 2
        setResult(await createUser(username, genderValue, referrer));
    };

    // Import Account
    const handleImport = async () => {
        setLoading(true);
        setResult(await importUser(username, secret));
    };

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="container">
            <h1>{isCreating ? 'Create Account' : 'Import Account'}</h1>

            <div className="inner-container">
                <input
                    type="text"
                    placeholder="Username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />

                {isCreating ? (
                    <>
                        <Select className='select'
                            options={genderOptions}
                            value={gender}
                            onChange={setGender}
                            placeholder="Select Gender"
                        />
                        <button onClick={handleCreate}>Create Account</button>
                    </>
                ) : (
                    <>
                        <input
                            type="password"
                            placeholder="Profile secret"
                            value={secret}
                            onChange={(e) => setSecret(e.target.value)}
                        />
                        <button onClick={handleImport}>Import Account</button>
                    </>
                )}
                {error && (<p style={{color:'#ff7675', textAlign:'center'}}>{error}</p>)}
                <button className="switch-button" onClick={() => setIsCreating(!isCreating)}>
                    {isCreating ? 'Switch to Import Account' : 'Switch to Create Account'}
                </button>
            </div>
            <button className="reset-button" onClick={() => localStorage.clear()}>
                Clear Cache
            </button>
        </div>
    );
};

export default CreateImportAccount;