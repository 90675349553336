import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from 'react-router-dom';
import { loggedIn } from './ContractsInteraction/account';
import CreateImportAccount from './pages/CreateImportAccount';
import Homepage from './pages/Homepage';
import EditProfile from './pages/EditProfile';
import Profile from './pages/Profile';
import Wallets from './pages/Wallets';
import DataManager from './pages/DataManager';

function App() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const refParam = params.get('ref');
  const userIdParam = params.get('userId');
  const usernameParam = params.get('username');

  const accountExists = loggedIn();

  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/data-manager" element={<DataManager />} />
      <Route path="/create-import" element={<CreateImportAccount />} />
      <Route path="/profile" element={<Profile />} />

      {/* Private Routes */}
      {accountExists && (
        <>
          <Route path="/leaderboard" element={<DataManager />} />
          <Route path="/edit-profile" element={<EditProfile />} />
          <Route path="/wallets" element={<Wallets />} />
        </>
      )}

      {/* Redirects */}
      {(!userIdParam && !usernameParam) ? (
        <>
          {(!accountExists) ? (
            <Route
              path="*"
              element={
                <Navigate
                  to={
                    refParam
                      ? `/create-import?ref=${refParam}`
                      : '/create-import'
                  }
                  replace
                />
              }
            />
          ) : (
            <>
              <Route path="/" element={<Homepage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </>
      ) : (
        <Route
          path="*"
          element={
            <Navigate
              to={
                usernameParam
                  ? `/profile?username=${usernameParam}`
                  : `/profile?userId=${userIdParam}`
              }
              replace
            />
          }
        />
      )}
    </Routes>
  );
}

export default function AppWrapper() {
  return (
    <Router>
      <App />
    </Router>
  );
}