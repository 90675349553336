import { contracts } from "./contracts";
import { accountAddress } from "./account.js";

/**
 * Retrieves the user level associated with the provided address.
 * @param {string} [address=accountAddress] - The address to fetch the level for.
 * @returns {Promise<number|null>} - The user level or null on error.
 */
const level = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.levels.getUserLevel(address));
    } catch (e) {
        console.error('ERROR:', e);
        return null;
    }
};

/**
 * Retrieves the user badge associated with the provided address.
 * @param {string} [address=accountAddress] - The address to fetch the badge for.
 * @returns {Promise<string|null>} - The user badge or null on error.
 */
const badge = async (address = accountAddress) => {
    try {
        return await contracts.levels.getUserBadge(address);
    } catch (e) {
        console.error('ERROR:', e);
        return "None";
    }
};

export { level as getLevel, badge as getBadge};
