
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { faCopy, faExternalLink, faLock } from '@fortawesome/free-solid-svg-icons';
import * as userAccount from '../ContractsInteraction/account.js';
import { Loading } from '../Components/Loading.js';
import { IconButton } from '../Components/IconButton.js';
import { copyToClipboard, formatNumber } from '../ContractsInteraction/utils/utils.js';
import Modal from '../Components/Modal.js';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getAccesBalance, getTotalAcces, getTotalLockedAcces, lockAcces, sendAcces } from '../ContractsInteraction/acces.js';
import { getUsername } from '../ContractsInteraction/profile.js';

function Wallets() {
    const [userContractAddress, setUserContractAddress] = useState('');
    const [username, setUsername] = useState('');
    const [wallets, setWallets] = useState([]);
    const [walletsWithBalance, setWalletsWithBalance] = useState([]);
    const [threshold, setThreshold] = useState(0);
    const [totalBalance, setTotalBalance] = useState(0);
    const [accesBalance, setAccesBalance] = useState(0);
    const [lockedAccesBalance, setLockedAccesBalance] = useState(0);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [isSendModalOpen, setSendModalOpen] = useState(false);
    const [isLockModalOpen, setLockModalOpen] = useState(false);
    const [currentWallet, setCurrentWallet] = useState('');
    const [sendTo, setSendTo] = useState('');
    const [sendAmount, setSendAmount] = useState('');
    const minDate = new Date(new Date().getTime() + 90 * 24 * 60 * 60 * 1000);
    const [lockTime, setLockTime] = useState(minDate);
    const [lockAmount, setLockAmount] = useState('');
    const navigate = useNavigate();
    const closeSendModal = () => {
        setCurrentWallet('');
        setSendTo('');
        setSendAmount('');
        setSendModalOpen(false);
    };

    const closeLockModal = () => {
        setLockModalOpen(false);
    };

    useEffect(() => {
        const getData = async () => {
            try {
                if (!userContractAddress) {
                    setUserContractAddress(userAccount.accountAddress);
                    return;
                }
                if (!userContractAddress) {
                    navigate('/');
                    return;
                }
                setLoading(false);
                setUsername(await getUsername());
                setThreshold(await userAccount.getThreshold());
                setTotalBalance(await getTotalAcces());
                setAccesBalance(await getAccesBalance());
                setLockedAccesBalance(await getTotalLockedAcces());
                setWallets(await userAccount.getWallets());
            } catch (err) {
                console.error('Failed to get user data', err);
                setLoading(false);
            };
        };
        getData();
    }, [userContractAddress, navigate]);

    useEffect(() => {
        const getWalletsWithBalance = async () => {
            let walletsWithBalance = [];
            await wallets.forEach(async wallet => {
                const balance = await getAccesBalance(wallet);
                walletsWithBalance.push({ address: wallet, balance: balance });
                setWalletsWithBalance(walletsWithBalance);
            });
        };
        if (wallets.length > 0) {
            getWalletsWithBalance();
        }
    }, [wallets]);

    if (loading) {
        return <Loading />;
    }

    return (
        <div className="user-profile">
            <h1>{username} Wallets</h1>
            <h3>
                Total ACCES Balance: {formatNumber(parseFloat(totalBalance) + parseFloat(accesBalance))} ACCES
            </h3>
            <h3>
                Contract balance: {formatNumber(accesBalance)} ACCES
                <IconButton icon={faCopy} onClick={() => copyToClipboard(userContractAddress)} />
                <IconButton icon={faLock} onClick={() => setLockModalOpen(true)} />
                <IconButton icon={faExternalLink} onClick={() => { setCurrentWallet(userContractAddress); setSendModalOpen(true); }} />
            </h3>
            <h3>
                Locked Contract balance: {formatNumber(lockedAccesBalance)} ACCES
            </h3>
            <h3 style={{ color: '#74b9ff' }}>{username} has {wallets.length} owners, with {threshold} threshold</h3>
            <table className="profile-table">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Wallet Address</th>
                        <th>Balance</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {walletsWithBalance.map((wallet, index) => (
                        <tr key={index}>
                            <td style={{ width: 'auto' }} className="label">{index}</td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(wallet.address)} />
                                {wallet.address}
                            </td>
                            <td className="value">ACCES {formatNumber(wallet.balance)}</td>
                            <td className="value">
                                <IconButton icon={faExternalLink} onClick={() => { setCurrentWallet(wallet); setSendModalOpen(true); }} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                className="margin-top-10px"
                onClick={async () => {
                    setProcessing(true);
                    await userAccount.addWallet();
                    setWallets(await userAccount.getWallets());
                    setProcessing(false);
                }}
                disabled={processing}
            >Add New Wallet</button>
            <button
                style={{ marginTop: '0px' }}
                onClick={async () => {
                    setProcessing(true);
                    await userAccount.addWallet(true);
                    setThreshold(await userAccount.getThreshold());
                    setWallets(await userAccount.getWallets());
                    setProcessing(false);
                }}
                disabled={processing}
            >Add New Wallet & Increase threshold</button>
            <button
                className="reset-button"
                onClick={() => {
                    navigate('/');
                }}
            >Back to Home</button>
            <Modal title='Lock ACCES' isOpen={isLockModalOpen} onClose={closeLockModal}>
                <input
                    type="text"
                    placeholder="Enter the amount you want to lock."
                    value={lockAmount}
                    disabled={processing}
                    onChange={(e) => setLockAmount(e.target.value)}
                />
                <DatePicker
                    selected={lockTime}
                    minDate={minDate}
                    onChange={(date) => setLockTime(date)}
                    dateFormat="yyyy-MM-dd" // Format the date as needed
                    placeholderText="Select Lock Time"
                    className="custom-datepicker"
                />
                <button
                    disabled={!lockTime || !lockAmount || processing}
                    onClick={async () => {
                        setProcessing(true);
                        await lockAcces(new Date(lockTime).getTime(), lockAmount);
                        setAccesBalance(await getAccesBalance());
                        setLockedAccesBalance(await getTotalLockedAcces());
                        setTotalBalance(await getTotalAcces());
                        setProcessing(false);
                        closeLockModal();
                    }}>Lock ACCES</button>
            </Modal>
            <Modal title='Send ACCES' isOpen={isSendModalOpen} onClose={closeSendModal}>
                <input
                    type="text"
                    placeholder="From wallet."
                    value={currentWallet.address ? currentWallet.address : currentWallet}
                    disabled={true}
                />
                <input
                    type="text"
                    placeholder="Enter the wallet address you want to send to."
                    value={sendTo}
                    disabled={processing}
                    onChange={(e) => setSendTo(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Enter the amount you want to send."
                    value={sendAmount}
                    disabled={processing}
                    onChange={(e) => setSendAmount(e.target.value)}
                />
                <button
                    disabled={!sendTo || !sendAmount || processing}
                    onClick={async () => {
                        setProcessing(true);
                        await sendAcces(currentWallet.address ? currentWallet.addWallet : currentWallet, sendTo, sendAmount);
                        setAccesBalance(await getAccesBalance());
                        setTotalBalance(await getTotalAcces());
                        setWallets(await userAccount.getWallets());
                        setProcessing(false);
                        closeSendModal();
                    }}>Send ACCES</button>
            </Modal>
        </div>
    );
}
export default Wallets;
