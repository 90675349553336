import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import countryList from 'react-select-country-list';
import { editProfile, getCountry, getDateOfBirth, getGender, getNickname, getUsername, hasSetProfile, setUserProfile } from '../ContractsInteraction/profile.js';
import { editBio, getBio, getMaxBioLength, hasSetBio, setUserBio } from '../ContractsInteraction/bio.js';
import { loggedIn } from '../ContractsInteraction/account.js';

const EditProfile = () => {
  const [username, setUsername] = useState('');
  const [nickname, setNickname] = useState('');
  const [dob, setDob] = useState(null);
  const [country, setCountry] = useState(null);
  const [gender, setGender] = useState({ value: 'male', label: 'Male' });
  const [bio, setBio] = useState('');
  const [isProfileProcessing, setIsProfileProcessing] = useState(false);
  const [isBioProcessing, setIsBioProcessing] = useState(false);
  const [profileInitialized, setProfileInitialized] = useState(false);
  const [bioInitialized, setBioInitialized] = useState(false);
  const [bioMaxLength, setMaxBioLength] = useState(100);
  const navigate = useNavigate();
  const countries = countryList().getData();

  const genderOptions = [
    { value: 'male', label: 'Male' },
    { value: 'female', label: 'Female' }
];

  const checkProfileStatus = useCallback(async () => {
    setUsername(await getUsername());
    setGender(await getGender() === 1 ? { value: 'male', label: 'Male' } : { value: 'female', label: 'Female' });
    const isProfileSet = await hasSetProfile();
    setProfileInitialized(isProfileSet);
    if (isProfileSet) {
      setNickname(await getNickname());
      const savedCountry = await getCountry();
      setCountry(countries.find(c => c.label === savedCountry));
      const savedDob = await getDateOfBirth();
      if (savedDob) {
        setDob(new Date(savedDob));
      }
    }
    setIsProfileProcessing(false);
  }, [countries]);

  const checkBioStatus = useCallback(async () => {
    const isBioSet = await hasSetBio();
    setMaxBioLength(await getMaxBioLength());
    setBioInitialized(isBioSet);
    if (isBioSet) {
      setBio(await getBio());
    }
    setIsBioProcessing(false);
  }, []);

  useEffect(() => {
    if (!loggedIn()) {
      navigate('/create-import');
      return;
    }
    checkProfileStatus();
    checkBioStatus();
  }, [checkProfileStatus, checkBioStatus, navigate]);

  const handleSubmitprofile = async () => {
    if (!nickname || !dob || !country) {
      alert(`Please enter valid data.`);
      return;
    }
    setIsProfileProcessing(true);
    let response;
    if (profileInitialized) {
      const genderValue = gender.value === 'male' ? 1 : 2; // Male is 1, Female is 2
      response = await editProfile(username, nickname, dob.toISOString().split('T')[0], country.label, genderValue);
    } else {
      response = await setUserProfile(nickname, dob.toISOString().split('T')[0], country.label);
    }
    if (response && response.status === true && response.txHash) {
      setTimeout(async () => {
        setProfileInitialized(await hasSetProfile());
        console.log(`Profile ${profileInitialized ? 'edited' : 'set'} successfully, with hash: ${response.txHash}`);
        setIsProfileProcessing(false);
      }, 2500);
    } else if (response && response.error) {
      console.error(response.error);
      alert(response.error);
      setIsProfileProcessing(false);
    } else {
      alert("Something went wrong...");
      setIsProfileProcessing(false);
    }
  };

  const handleSubmitBio = async () => {
    if (!bio) {
      alert('Please enter bio.');
      return;
    }
    setIsBioProcessing(true);
    let response;
    if (bioInitialized) {
      response = await editBio(bio);
    } else {
      response = await setUserBio(bio);
    }
    if (response && response.status === true && response.txHash) {
      setTimeout(async () => {
        setBioInitialized(await hasSetBio());
        console.log(`Bio ${bioInitialized ? 'edited' : 'set'} successfully, with hash: ${response.txHash}`);
        setIsBioProcessing(false);
      }, 2500);
    } else if (response && response.error) {
      console.error(response.error);
      alert(response.error);
      setIsBioProcessing(false);
    } else {
      alert("Something went wrong...");
      setIsBioProcessing(false);
    }
  };

  return (
    <div className='container'>
      {/* Profile */}
      <div className='inner-container'>
        <h1>{profileInitialized ? "Edit Profile" : "Set Profile"}</h1>
        {profileInitialized && (
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        )}
        <input
          type="text"
          placeholder="Nickname"
          value={nickname}
          onChange={(e) => setNickname(e.target.value)}
        />

        <DatePicker
          selected={dob}
          onChange={(date) => setDob(date)}
          dateFormat="yyyy-MM-dd" // Format the date as needed
          placeholderText="Select Date of Birth"
          className="custom-datepicker"
        />

        <Select
          options={countries}
          value={country}
          onChange={setCountry}
          placeholder="Select Country"
          className="select"
        />

        {profileInitialized && (
          <Select className='select'
            options={genderOptions}
            value={gender}
            onChange={setGender}
            placeholder="Select Gender"
          />
        )}
        <button
          onClick={handleSubmitprofile}
          disabled={isProfileProcessing}
        >{isProfileProcessing ? "Processing..." : "Submit Profile"}</button>
      </div>
      {/* Bio */}
      <div className='inner-container'>
        <h1>{bioInitialized ? "Edit Bio" : "Set Bio"}</h1>

        <textarea
          className='bio-input'
          placeholder="Enter your bio (max 250 characters)"
          value={bio}
          onChange={(e) => setBio(e.target.value)}
          maxLength={bioMaxLength}
        />

        <button
          onClick={handleSubmitBio}
          disabled={isBioProcessing}
        >{isBioProcessing ? "Processing..." : "Submit Bio"}</button>
      </div>
      <button
        className="home-btn"
        onClick={() => navigate('/')}
        disabled={isBioProcessing || isProfileProcessing}
      >Back to Home</button>
    </div>
  );

};

export default EditProfile;
