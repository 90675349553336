import { contracts } from "./contracts";

/**
 * Retrieve paginated user data as structured objects
 * @param {number} offset - Starting index for pagination
 * @param {number} limit - Number of users to retrieve
 * @returns {Promise<{ users: object[], total: number }>} - Paginated user data and total count
 */
export const getPaginatedUsersData = async (offset, limit) => {
    try {
        const { users, total } = await contracts.dataManager.getPaginatedUsersData(offset, limit);
        return { users, total };
    } catch (error) {
        console.error("Error fetching paginated users data:", error);
        return { users: [], total: 0 };
    }
};