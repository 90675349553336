import { Wallet, ZeroAddress } from 'ethers';
import { getStoredUser, storeUser } from './utils/utils';
import { contracts, initializeContracts, provider } from './contracts';
import { forwarderAddress, relayServer, userCreatorAddress } from './utils/strings';
import { propose } from './utils/transaction';
import { accountAddressByName, generateNewAddress, legendaryOli } from './utils/contractUtils';

// Default user account address
var accountAddress = getStoredUser()?.address;

/**
 * Initializes the wallet from stored mnemonic or creates a new one.
 * @returns {Wallet} - Returns an initialized wallet instance.
 */
const initializeWalletAndContracts = () => {
    const storedUser = getStoredUser();
    let tempWallet = storedUser
        ? Wallet.fromPhrase(storedUser.mnemonic)
        : Wallet.createRandom();
    initializeContracts(tempWallet);
    return tempWallet;
};


// Initialize wallet
var wallet = initializeWalletAndContracts();

/**
 * Fetches the main wallet associated with the account.
 * @param {string} address - The address to fetch the main wallet for.
 * @returns {Promise<string|null>} - Main wallet address or null if not found.
 */
const mainWallet = async (address = accountAddress) => {
    try {
        const mainWallet = await contracts.userAccount(address).mainWallet();
        return mainWallet && mainWallet !== ZeroAddress ? mainWallet : null;
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches all wallets associated with the account.
 * @param {string} address - The address to fetch wallets for.
 * @returns {Promise<Array>|null} - List of wallet addresses or null if not found.
 */
const wallets = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).getAllWallets();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the threshold of the account.
 * @param {string} address - The address to fetch the threshold for.
 * @returns {Promise<number|null>} - Threshold value or null if not found.
 */
const threshold = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.userAccount(address).getThreshold());
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Fetches the owners associated with the account.
 * @param {string} address - The address to fetch owners for.
 * @returns {Promise<Array>|null} - List of owner addresses or null if not found.
 */
const owners = async (address = accountAddress) => {
    try {
        return await contracts.userAccount(address).getOwners();
    } catch (e) {
        console.error("ERROR:", e);
        return null;
    }
};

/**
 * Adds a new wallet address to the user's account.
 * @param {boolean} increaseThreshold - Whether to increase the wallet threshold.
 * @returns {Promise<object>} - Transaction result status and data.
 */
const addWallet = async (increaseThreshold) => {
    try {
        const newThreshold = parseInt(await threshold()) + (increaseThreshold ? 1 : 0);
        const newWallet = await generateNewAddress(wallet.mnemonic.phrase, await wallets());
        const addWalletData = contracts.userAccount(accountAddress).interface.encodeFunctionData('addWallet', [newWallet, newThreshold]);
        const result = await propose(addWalletData);
        return result;
    } catch (e) {
        console.error("ERROR:", e);
        return { status: false, error: e };
    }
};

/**
 * Creates a new user account.
 * @param {string} username - The username for the new account.
 * @param {number} gender - The gender identifier for the account.
 * @param {string} referrer - The referrer's address.
 * @returns {Promise<{status: boolean, address?: string, error?: string}>} - Status and address if successful, or error message.
 */
const createUser = async (username, gender, referrer) => {
    if (!username) {
        return { status: false, error: 'Please enter a username.' };
    }
    if (!validateUsername(username)) {
        return { status: false, error: 'Username is not valid, use characters from 5 to 16' };
    }
    if (referrer && !/^0x[a-fA-F0-9]{40}$/.test(referrer)) {
        referrer = await accountAddressByName(referrer);
    }
    if (referrer && (!/^0x[a-fA-F0-9]{40}$/.test(referrer) || referrer === ZeroAddress)) {
        referrer = await legendaryOli();
    }
    if (!referrer) {
        referrer = await legendaryOli();
    }
    const encodedFunctionCall = contracts.userCreator.interface.encodeFunctionData('createUserAccountAndActivate', [username, gender, referrer]);

    const request = {
        from: wallet.address,
        to: userCreatorAddress,
        value: 0,
        gas: 5000000,
        nonce:"0",
        data: encodedFunctionCall,
    };

    try {
        const chainId = (await provider.getNetwork()).chainId;
        const domain = {
            name: 'MinimalForwarder',
            version: '0.0.1',
            chainId: parseInt(chainId),
            verifyingContract: forwarderAddress,
        };
        const types = {
            ForwardRequest: [
                { name: 'from', type: 'address' },
                { name: 'to', type: 'address' },
                { name: 'value', type: 'uint256' },
                { name: 'gas', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'data', type: 'bytes' },
            ],
        };

        const signature = await wallet.signTypedData(domain, types, request);
        console.log('Signature:', signature);
        const requestWithSignature = {
            username,
            ...request,
            signature: signature,
            gasPrice: (await provider.getFeeData()).gasPrice.toString(),
        };

        const response = await fetch(`${relayServer}/createUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestWithSignature),
        });

        if (response.ok) {
            const responseData = await response.json();
            if (responseData.success) {
                console.log(`User account created! Contract Address: ${responseData.address}`);
                storeUser({
                    address: responseData.address,
                    txHash: responseData.txHash,
                    mnemonic: wallet.mnemonic ? wallet.mnemonic.phrase : wallet.privateKey
                });
                wallet = initializeWalletAndContracts();
                accountAddress = responseData.address;
                return { status: true, address: responseData.address };
            } else {
                console.error(responseData);
                return { status: false, error: responseData };
            }
        } else {
            const error = await response.json();
            console.error("Error executing transaction:", error["error"]);
            return { status: false, error: error["error"] };
        }
    } catch (error) {
        console.error('Error signing and sending transaction:', error);
        return { status: false, error: error.message };
    }
};

/**
 * Imports a user account by mnemonic phrase.
 * @param {string} username - Username to import.
 * @param {string} phrase - Mnemonic phrase for the wallet.
 * @returns {Promise<object>} - Import result.
 */
const importUser = async (username, phrase) => {
    try {
        let tempWallet;
        if (!validateUsername(username)) {
            return { status: false, error: 'Username is not valid, use characters from 5 to 16' };
        }
        if (phrase.split(' ').length === 12) {
            tempWallet = Wallet.fromPhrase(phrase).connect(provider);
        } else {
            return { status: false, error: 'Invalid access key, it should be 12 words' };
        }
        const userAccountAddress = await accountAddressByName(username);
        const userMainWallet = await mainWallet(userAccountAddress);
        if (userMainWallet !== tempWallet.address) {
            return { status: false, error: 'Wallet does not match user account' };
        }
        storeUser({
            address: userAccountAddress,
            mnemonic: tempWallet.mnemonic ? tempWallet.mnemonic.phrase : tempWallet.privateKey
        });
        wallet = initializeWalletAndContracts();
        accountAddress = userAccountAddress;
        return { status: true, address: userAccountAddress };
    } catch (e) {
        console.error('Could not import', e);
        return { status: false, error: e.message };
    }
};

/**
 * Validates the username based on length and characters.
 * @param {string} username - The username to validate.
 * @returns {boolean} - True if valid, otherwise false.
 */
const validateUsername = (username) => {
    return !(username.length < 5 || /^[0-9]+$/.test(username) || username.length > 16);
};

/**
 * Checks if the user is logged in.
 * @returns {boolean} - True if logged in, otherwise false.
 */
const loggedIn = () => {
    return getStoredUser() !== null;
};

/**
 * Logs out the user by clearing local storage.
 */
const logout = () => {
    localStorage.clear();
    accountAddress = null;
    wallet = initializeWalletAndContracts();
};

export {
    wallet,
    accountAddress,
    mainWallet as getMainWallet,
    wallets as getWallets,
    threshold as getThreshold,
    owners as getOwners,
    addWallet,
    createUser,
    importUser,
    validateUsername,
    loggedIn,
    logout
};