import { contracts } from './contracts';
import { accountAddress } from './account';
import { propose } from './utils/transaction';
import { blacxesAddress, blacxesLockerAddress, relayServer } from './utils/strings';
import { getBigInt } from 'ethers';

/**
 * Fetches the balance of Blacxes tokens for a given address.
 * @param {string} address - The address to check the balance for.
 * @returns {Promise<number>} - Blacxes token balance.
 */
const balanceOf = async (address = accountAddress) => {
    try {
        return await contracts.blacxes.balanceOf(address, 0);
    } catch (e) {
        console.error("ERROR fetching Blacxes balance:", e);
        return null;
    }
};

/**
 * Fetches the locked balance of Blacxes tokens for a given address.
 * @param {string} address - The address to check the balance for.
 * @returns {Promise<number>} - Blacxes locked token balance.
 */
const lockedBalance = async (address = accountAddress) => {
    try {
        return await contracts.blacxesLocker.getTotalLockedForUser(address);
    } catch (e) {
        console.error("ERROR fetching Blacxes balance:", e);
        return null;
    }
};

/**
 * Fetches the free balance of Blacxes tokens for a given address.
 * @param {string} address - The address to check the free balance for.
 * @returns {Promise<number>} - Blacxes locked token balance.
 */
const freeBlacxes = async () => {
    try {
        return await contracts.blacxes.freeBlacxes();;
    } catch (e) {
        console.error("ERROR fetching Free Blacxes balance:", e);
        return null;
    }
};

/**
 * Locks Blacxes tokens for a specific user.
 * @param {number} amount
 * @param {string} lockTime
 * @returns {Promise<object>} - Result of the lockTokens transaction.
 */
const lockTokens = async (amount, lockTime) => {
    try {
        if(!(await isApproved()))
            await approveLocker();
        console.log("Locker approved:", await isApproved());
        const lockTokensData = contracts.blacxesLocker.interface.encodeFunctionData('lockTokens', [amount, lockTime]);
        return await propose(lockTokensData, blacxesLockerAddress);
    } catch (e) {
        console.error("ERROR locking Blacxes tokens:", e);
        return null;
    }
};


/**
 * approve Locker address to lock tokens
 */
const approveLocker = async () => {
    try {
        if(await isApproved())
            return;
        const approveData = contracts.blacxes.interface.encodeFunctionData('setApprovalForAll', [blacxesLockerAddress, true]);
        return await propose(approveData, blacxesAddress);
    } catch (e) {
        console.error("ERROR approving locker:", e);
    }
};

/**
 * approve Locker address to lock tokens
 */
const isApproved = async () => {
    try {
        return await contracts.blacxes.isApprovedForAll(accountAddress, blacxesLockerAddress);
    } catch (e) {
        console.error("ERROR approving locker:", e);
        return null;
    }
};

/**
 * Retrieves the list of available Blacxes packages.
 * @returns {Promise<Array>} - Array of available Blacxes packages.
 */
const getAvailablePackages = async () => {
    try {
        return await contracts.blacxes.getAvailablePackages();
    } catch (e) {
        console.error("ERROR fetching available packages:", e);
        return null;
    }
};

/**
 * Awards Blacxes tokens to a specified user.
 * @param {string} address - Address of the user to receive tokens.
 * @param {number} packageId - ID of the Blacxes package.
 * @param {number} amount - Amount of tokens to give.
 * @returns {Promise<void>}
 */
 const giveBlacxesToUser = async (address = accountAddress, packageId = 0, amount = 10) => {
    try {
        const response = await fetch(`${relayServer}/giveBlacxesToUser`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                user: address,
                packageId: packageId,
                amount: amount
            })
        });
        if (response.ok) {
            const responseData = await response.json();
            console.log(`Transaction mined with txHash: ${responseData.txHash}`);
        } else {
            const error = await response.json();
            console.error("Error executing transaction:", error["error"]);
        }
    } catch (e) {
        console.error('Error giving user Blacxes:', e);
    }
};

/**
 * Fetches the total Blacxes tokens for a given address.
 * @param {string} address - The address to get the total Blacxes for.
 * @returns {Promise<number>} - Total Blacxes balance.
 */
const totalBlacxes = async (address = accountAddress) => {
    try {
        return parseInt(await contracts.userAccount(address).totalBlacxes());
    } catch (e) {
        console.error("ERROR fetching total Blacxes:", e);
        return null;
    }
};

/**
 * Fetches the base price of one Blacxes token.
 * @returns {Promise<number>} - Base price of a Blacxes token.
 */
const basePrice = async () => {
    try {
        return await contracts.blacxes.basePrice();
    } catch (e) {
        console.error("ERROR fetching Blacxes price:", e);
        return null;
    }
}
/**
 * Buys Blacxes tokens.
 * @param {number} amount - Amount of tokens to buy.
 * @returns {Promise<object>} - Result of the buy transaction.
 */
const buyBlacxes = async (amount) => {
    try {
        const price = (await basePrice()) * (getBigInt(amount));
        const txResponse = await contracts.blacxes.buy(amount, {value: price});
        await txResponse.wait();
        console.log('Transaction mined:', txResponse.hash);
        return txResponse.hash;
    } catch (e) {
        console.error(e.reason);
        return null;
    }
};

// const buyBacxesPackage = async (amount, address = accountAddress, tokenId = 0n) => {
//     try {
//         const buyData = contracts.blacxes.interface.encodeFunctionData('buyPackage', [address, tokenId]);
//         return await propose(buyData, blacxesAddress, amount);
//     } catch (e) {
//         console.error("ERROR buying Blacxes tokens:", e);
//         return null;
//     }
// };

window.giveBlacxesToUser = giveBlacxesToUser;
export {
    balanceOf as getBlacxesBalance,
    freeBlacxes as getFreeBlacxesBalance,
    lockedBalance as getLockedBlacxesBalance,
    giveBlacxesToUser,
    lockTokens as lockBlacxes,
    getAvailablePackages,
    totalBlacxes as getTotalBlacxes,
    basePrice as getBlacxesPrice,
    buyBlacxes
};