import { accountAddress, wallet } from "./account";
import {ethers} from "ethers";
import { contracts, provider } from "./contracts";
import { getOwnersWallet } from "./utils/contractUtils";
import { propose } from "./utils/transaction";
import { accesLockerAddress } from "./utils/strings";

/**
 * Fetches the total Acces balance for a given address.
 * @param {string} address - The address to get the total Acces for.
 * @returns {Promise<string>} - Formatted total Acces balance.
 */
const totalAcces = async (address = accountAddress) => {
    try {
        return ethers.formatUnits(await contracts.userAccount(address).totalAcces(), 18);
    } catch (e) {
        console.error("ERROR fetching total Acces:", e);
        return null;
    }
};

/**
 * Fetches the total locked Acces balance for a given address.
 * @param {string} address - The address to get the total locked Acces for.
 * @returns {Promise<string>} - Formatted total locked Acces balance.
 */
const totalLockedAcces = async (address = accountAddress) => {
    try {
        return ethers.formatUnits(await contracts.userAccount(address).totalLockedAcces(), 18);
    } catch (e) {
        console.error("ERROR fetching total locked Acces:", e);
        return null;
    }
};

/**
 * Fetches the Acces balance of an address.
 * @param {string} address - The address to get the balance for.
 * @returns {Promise<string>} - Formatted Acces balance.
 */
const accessBalance = async (address = accountAddress) => {
    try {
        return ethers.formatUnits(await provider.getBalance(address), 18);
    } catch (e) {
        console.error("ERROR fetching Acces balance:", e);
        return null;
    }
};

/**
 * Sends Acces from one wallet to another.
 * @param {string|null} fromAddress - The sender's address (if null, defaults to accountAddress).
 * @param {string} toAddress - The recipient's address.
 * @param {string|number} amountInEther - The amount of Acces to send, in Ether.
 * @returns {Promise<object|null>} - Transaction result with status and transaction hash.
 */
const sendAcces = async (fromAddress = null, toAddress, amountInEther) => {
    try {
        let walletToSendFrom = wallet;
        if (fromAddress !== null) {
            try {
                walletToSendFrom = getOwnersWallet(fromAddress);
            } catch (e) {
                console.error("ERROR fetching owner's wallet:", e);
                return { status: false, error: e.message };
            }
        }

        if (!walletToSendFrom || !toAddress || !amountInEther) return;

        if (walletToSendFrom !== accountAddress) {
            try {
                walletToSendFrom = walletToSendFrom.connect(provider);
                const amount = ethers.parseEther(amountInEther.toString());
                const tx = await walletToSendFrom.sendTransaction({
                    to: toAddress,
                    value: amount,
                });
                await tx.wait();
                console.log(`Sent ${amountInEther} Acces to ${toAddress}. Transaction hash: ${tx.hash}`);
                return { status: true, txHash: tx.hash };
            } catch (error) {
                console.error('Error sending Acces:', error);
                return { status: false, error: error.message };
            }
        } else {
            const amountInWei = ethers.parseEther(amountInEther.toString());
            try {
                const result = await propose(contracts.userAccount(accountAddress).interface.encodeFunctionData('withdrawAcces', [toAddress, amountInWei]));
                return result;
            } catch (error) {
                console.error('Error proposing transaction for withdrawAcces:', error);
                return { status: false, error: error.message };
            }
        }
    } catch (e) {
        console.error("ERROR in sendAcces:", e);
        return null;
    }
};

/**
 * Locks Acces tokens for a specified lock time.
 * @param {number} lockTime - The lock duration in seconds.
 * @returns {Promise<object|null>} - Transaction result with status and transaction hash.
 */
const lockCoins = async (lockTime, amount) => {
    try {
        const lockCoinsData = contracts.accesLocker.interface.encodeFunctionData('lockCoins', [lockTime]);
        return await propose(lockCoinsData, accesLockerAddress, ethers.parseEther(amount.toString()));
    } catch (e) {
        console.error("ERROR locking Acces:", e);
        return null;
    }
};

export {
    totalAcces as getTotalAcces,
    totalLockedAcces as getTotalLockedAcces,
    accessBalance as getAccesBalance,
    sendAcces,
    lockCoins as lockAcces
};
