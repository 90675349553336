import { Contract, JsonRpcProvider } from 'ethers';
import * as strings from './utils/strings';


var provider = null;
var wallet = null;
var contracts = {};

const addresses = {
    userCreator: strings.userCreatorAddress,
    levels: strings.levelsAddress,
    xp: strings.xpAddress,
    referral: strings.referralAddress,
    followerManager: strings.followerManagerAddress,
    blacxes: strings.blacxesAddress,
    blacxesLocker: strings.blacxesLockerAddress,
    accesLocker: strings.accesLockerAddress,
    bio: strings.bioAddress,
    oli: strings.oliAddress,
    dataManager: strings.dataManagerAddress,
    forwarder: strings.forwarderAddress
};

const initializeContracts = (userWallet) => {
    provider = new JsonRpcProvider(strings.rpcUrl);
    wallet = userWallet.connect(provider);
    contracts = {
        userAccount: (address) => new Contract(address, strings.userAccountABI, wallet),
        userCreator: new Contract(addresses.userCreator, strings.userCreatorABI, wallet),
        levels: new Contract(addresses.levels, strings.accesLevelABI, wallet),
        xp: new Contract(addresses.xp, strings.accesXPABI, wallet),
        referral: new Contract(addresses.referral, strings.referralABI, wallet),
        followerManager: new Contract(addresses.followerManager, strings.followerManagerABI, wallet),
        blacxes: new Contract(addresses.blacxes, strings.balcxesABI, wallet),
        blacxesLocker: new Contract(addresses.blacxesLocker, strings.blacxesLockerABI, wallet),
        accesLocker: new Contract(addresses.accesLocker, strings.accesLockerABI, wallet),
        bio: new Contract(addresses.bio, strings.userBioABI, wallet),
        oli: new Contract(addresses.oli, strings.oliNFTABI, wallet),
        dataManager: new Contract(addresses.dataManager, strings.accesDataManagerABI, wallet)
    };
};

export { initializeContracts, contracts, provider };