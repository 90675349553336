
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { faCopy, faExternalLink, faLock, faShare, faStore, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import 'react-datepicker/dist/react-datepicker.css';
import { ZeroAddress } from 'ethers';
import { IconButton } from '../Components/IconButton.js';
import { getShortString, copyToClipboard, formatNumber } from '../ContractsInteraction/utils/utils.js';
import { getRefereesCount, getReferralsCount, getReferrerUsername } from '../ContractsInteraction/referrals.js';
import { addXp, getTotalXP } from '../ContractsInteraction/xp.js';
import { getBadge, getLevel } from '../ContractsInteraction/levels.js';
import * as profile from '../ContractsInteraction/profile.js';
import { getFollowersCount, getFollowingCount } from '../ContractsInteraction/followers.js';
import { getAccesBalance, getTotalAcces, getTotalLockedAcces } from '../ContractsInteraction/acces.js';
import { getBlacxesBalance, getLockedBlacxesBalance, getTotalBlacxes } from '../ContractsInteraction/blacxes.js';
import { getOliBalance } from '../ContractsInteraction/oli.js';
import { accountAddress, logout, wallet } from '../ContractsInteraction/account.js';
import { getBio } from '../ContractsInteraction/bio.js';
import BlacxesModal from '../Components/BlacxesModal.js';

const scanUrl = process.env.REACT_APP_SCAN_URL;

function Homepage() {
    const [userContractAddress, setUserContractAddress] = useState('');
    const [referrer, setReferrer] = useState(ZeroAddress);
    const [username, setUsername] = useState('');
    const [nickname, setNickname] = useState('');
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [country, setCountry] = useState('');
    const [bio, setBio] = useState('');
    const [userId, setUserId] = useState(0);
    const [gender, setGender] = useState(1);
    const [totalAcces, setTotalAcces] = useState(0);
    const [totalBlacxes, setTotalBlacxes] = useState(0);
    const [accesBalance, setAccesBalance] = useState(0);
    const [lockedAccesBalance, setLockedAccesBalance] = useState(0);
    const [blacxesBalance, setBlacxesBalance] = useState(0);
    const [lockedBlacxesBalance, setLockedBlacxesBalance] = useState(0);
    const [oliBalance, setOliBalance] = useState(0);
    const [xp, setXp] = useState(0); // XP Counter
    const [level, setLevel] = useState(0);
    const [badge, setBadge] = useState('');
    const [followersCount, setFollowersCount] = useState(0);
    const [followingCount, setFollowingCount] = useState(0);
    const [floatingTexts, setFloatingTexts] = useState([]);
    const [xpCounter, setXpCounter] = useState(0);
    const [referralsCount, setReferralsCount] = useState(0);
    const [refereesCount, setRefereesCount] = useState(0);
    const [creationTxHash, setCreationTxHash] = useState(null);
    const [profileSecret, setProfileSecret] = useState('');
    const [blacxesStoreOpen, setBlacxesStoreOpen] = useState(false);

    const navigate = useNavigate();
    const relayServerUrl = process.env.REACT_APP_RELAY_SERVER_URL;

    useEffect(() => {
        const getData = async () => {
            try {
                if (!userContractAddress) {
                    setUserContractAddress(accountAddress);
                    return;
                }
                console.log('Retrieving user data');
                setProfileSecret(profile.getProfileSecret());
                setUserId(await profile.getUserId());
                setUsername(await profile.getUsername());
                setCreationTxHash(profile.getCreateTxHash());
                setNickname(await profile.getNickname() || 'Not-Set');
                setDateOfBirth(await profile.getDateOfBirth() || 'Not-Set');
                setCountry(await profile.getCountry() || 'Not-Set');
                setBio(await getBio() || 'Not-Set');
                setReferrer(await getReferrerUsername());
                setReferralsCount(await getReferralsCount());
                setRefereesCount(await getRefereesCount());
                setXp(await getTotalXP());
                setLevel(await getLevel());
                setBadge(await getBadge());
                setGender(await profile.getGender());
                setFollowersCount(await getFollowersCount());
                setFollowingCount(await getFollowingCount());
                setTotalAcces(await getTotalAcces());
                setTotalBlacxes(await getTotalBlacxes());
                setAccesBalance(await getAccesBalance());
                setLockedAccesBalance(await getTotalLockedAcces());
                setBlacxesBalance(await getBlacxesBalance());
                setLockedBlacxesBalance(await getLockedBlacxesBalance());
                setOliBalance(await getOliBalance());
            } catch (e) {
                console.error(e);
            }
        };
        getData();
    }, [userContractAddress, navigate]);

    useEffect(() => {
        const handleUnload = () => {
            if (!userContractAddress || xpCounter === 0) return;
            const data = JSON.stringify({
                user: userContractAddress,
                xp: xpCounter
            });

            const blob = new Blob([data], { type: 'application/json' });
            navigator.sendBeacon(`${relayServerUrl}/addXp`, blob);
        };

        window.addEventListener('beforeunload', handleUnload);

        return () => {
            window.removeEventListener('beforeunload', handleUnload);
        };
    }, [relayServerUrl, userContractAddress, xpCounter]);

    const handlePageClick = (event) => {
        if (!userContractAddress) return;
        const x = event.clientX;
        const y = event.clientY;
        setXpCounter((prevXpCounter) => prevXpCounter + 100);
        if ((xpCounter) > 10000) {
            addXp(xpCounter);
            setXpCounter(0);
        }
        setXp((prevXp) => prevXp + 100);
        const newFloatingText = {
            id: uuidv4(),
            x: x,
            y: y,
        };
        setFloatingTexts((prevTexts) => [...prevTexts, newFloatingText]);
        setTimeout(() => {
            setFloatingTexts((prevTexts) => prevTexts.filter((text) => text.id !== newFloatingText.id));
        }, 2000);
    };


    return (
        <div className="full-screen" onClick={handlePageClick}>
            <div className="user-profile">
                <h1>Welcome, {username}!</h1>
                <table className="profile-table">
                    <tbody>
                        <tr>
                            <td className="label">UserId</td>
                            <td className="value">
                                {getShortString(userId.toString(), 6, 8)}
                            </td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(userId.toString())} />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Username</td>
                            <td className="value">
                                {username}</td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(username)} />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">User Account</td>
                            <td className="value">
                                {getShortString(userContractAddress, 6, 8)}
                            </td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(userContractAddress)} />
                                <IconButton icon={faShare} onClick={() => copyToClipboard(`${window.location.host}?ref=${userContractAddress}`)} />
                                {profileSecret && (<IconButton icon={faLock} onClick={() => copyToClipboard(profileSecret)} />)}
                            </td>
                        </tr>
                        {wallet && (
                            <tr>
                                <td className="label">Wallet</td>
                                <td className="value">
                                    {getShortString(wallet.address, 6, 8)}
                                </td>
                                <td className="value">
                                    <IconButton icon={faCopy} onClick={() => copyToClipboard(wallet.address)} />
                                    <IconButton icon={faUserSecret} onClick={() => copyToClipboard(wallet.mnemonic ? wallet.mnemonic.phrase : wallet.privateKey)} />
                                </td>
                            </tr>
                        )}
                        {creationTxHash && <tr>
                            <td className="label">Creation TxHash</td>
                            <td className="value">
                                {getShortString(creationTxHash, 6, 8)}
                            </td>
                            <td className="value">
                                <IconButton icon={faCopy} onClick={() => copyToClipboard(creationTxHash)} />
                                <IconButton icon={faExternalLink} onClick={() => window.open(`${scanUrl}tx/${creationTxHash}`, '_blank')} />
                            </td>
                        </tr>}
                        <tr>
                            <td className="label">Nickname</td>
                            <td className="value">{nickname}</td>
                        </tr>
                        <tr>
                            <td className="label">Date Of Birth</td>
                            <td className="value">{dateOfBirth}</td>
                        </tr>
                        <tr>
                            <td className="label">Country</td>
                            <td className="value">{country}</td>
                        </tr>
                        <tr>
                            <td className="label">Bio</td>
                            <td className="value">{bio}</td>
                        </tr>
                        {
                            referrer && referrer !== ZeroAddress && (
                                <tr>
                                    <td className="label">Referrer</td>
                                    <td className="value">
                                        <IconButton icon={faCopy} onClick={() => copyToClipboard(referrer)} />
                                        {referrer}
                                    </td>
                                </tr>
                            )
                        }
                        <tr>
                            <td className="label">Referrals</td>
                            <td className="value">{referralsCount.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Referees</td>
                            <td className="value">{refereesCount.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">XP</td>
                            <td className="value">{xp?.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Level:</td>
                            <td className="value">{level?.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Badge:</td>
                            <td className="value">{badge}</td>
                        </tr>
                        <tr>
                            <td className="label">Gender:</td>
                            <td className="value">{
                                gender === 1 ? 'Male' : 'Female'
                            }</td>
                        </tr>
                        <tr>
                            <td className="label">Followers:</td>
                            <td className="value">{followersCount.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Following:</td>
                            <td className="value">{followingCount.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Total Acces:</td>
                            <td className="value">{`${formatNumber(totalAcces)} ACCES`}</td>
                        </tr>
                        <tr>
                            <td className="label">Acces Balance:</td>
                            <td className="value">{`${formatNumber(accesBalance)} ACCES`}</td>
                        </tr>
                        <tr>
                            <td className="label">Locked Acces:</td>
                            <td className="value">{`${formatNumber(lockedAccesBalance)} ACCES`}</td>
                        </tr>
                        <tr>
                            <td className="label">Total Blacxes:</td>
                            <td className="value">
                                {totalBlacxes.toString()}
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Blacxes:</td>
                            <td className="value">
                                {blacxesBalance.toString()}
                            </td>
                            <td className="value">
                                <IconButton icon={faStore} onClick={() => setBlacxesStoreOpen(true)} />
                            </td>
                        </tr>
                        <tr>
                            <td className="label">Locked Blacxes:</td>
                            <td className="value">{lockedBlacxesBalance.toString()}</td>
                        </tr>
                        <tr>
                            <td className="label">Oli Balance:</td>
                            <td className="value">{oliBalance.toString()}</td>
                        </tr>
                    </tbody>
                </table>
                <button
                    style={{
                        marginTop: '15px', marginBottom: '0px'
                    }}
                    onClick={() => {
                        console.log('Navigating to wallets');
                        navigate('/wallets');
                    }}
                >
                    Manage Wallets
                </button>
                <button
                    style={{
                        marginTop: '15px', marginBottom: '0px'
                    }}
                    onClick={() => {
                        navigate('/edit-profile');
                    }}
                >
                    Edit Profile
                </button>
                <button
                    style={{
                        marginTop: '15px', marginBottom: '0px'
                    }}
                    onClick={() => {
                        navigate('/leaderboard');
                    }}
                >
                    Leaderboard
                </button>
                <button
                    style={{
                        marginTop: '15px', marginBottom: '15px'
                    }}
                    className="reset-button"
                    onClick={async () => {
                        const userConfirmed = window.confirm(`Hello, ${username}!\n\nPlease ensure you have copied your profile secret. Without it, you may not be able to access your account again. Additionally, remember your username as you will need it to import your account using your secret and username.\n\nDo you want to proceed with logout?`);

                        if (userConfirmed) {
                            await copyToClipboard(profileSecret);
                            alert(`Profile secret copied to clipboard. Please save it in a safe place along with your username (${username}).`);
                            logout();
                            navigate('/create-import');
                        }
                    }}
                >
                    Log out
                </button>
                {floatingTexts.map((text) => (
                    <div
                        key={text.id}
                        className="floating-text"
                        style={{ top: text.y, left: text.x }}
                    >
                        +100 XP
                    </div>
                ))}
                <BlacxesModal isOpen={blacxesStoreOpen} onClose={ ()=> {setBlacxesStoreOpen(false)}}/>
            </div>
        </div>
    );
}
export default Homepage;
