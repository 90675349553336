export const relayServer = process.env.REACT_APP_RELAY_SERVER_URL;
export const rpcUrl = process.env.REACT_APP_RPC_URL;

// Contract Addresses
export const userCreatorAddress = process.env.REACT_APP_CREATOR_PROXY_ADDRESS;
export const levelsAddress = process.env.REACT_APP_ACCES_LEVELS_ADDRESS;
export const xpAddress = process.env.REACT_APP_ACCES_XP_ADDRESS;
export const referralAddress = process.env.REACT_APP_ACCES_REFERRAL_ADDRESS;
export const followerManagerAddress = process.env.REACT_APP_FOLLOWER_MANAGER_ADDRESS;
export const blacxesAddress = process.env.REACT_APP_BLACXES_ADDRESS;
export const blacxesLockerAddress = process.env.REACT_APP_BLACXES_LOCKER_ADDRESS;
export const accesLockerAddress = process.env.REACT_APP_ACCES_TOKEN_LOCKER_ADDRESS;
export const bioAddress = process.env.REACT_APP_USER_BIO_ADDRESS;
export const oliAddress = process.env.REACT_APP_OLINFT_ADDRESS;
export const forwarderAddress = process.env.REACT_APP_FORWARDER_ADDRESS
export const dataManagerAddress = process.env.REACT_APP_ACCES_DATA_MANAGER;

// ABIs
export const userAccountABI = require('./../ABIs/UserAccountABI.json');
export const accesLevelABI = require('./../ABIs/AccesLevelABI.json');
export const accesXPABI = require('./../ABIs/AccesXPABI.json');
export const referralABI = require('./../ABIs/ReferralABI.json');
export const userCreatorABI = require('./../ABIs/UserCreatorABI.json');
export const userBioABI = require('./../ABIs/UserBioABI.json');
export const followerManagerABI = require('./../ABIs/FollowerManagerABI.json');
export const balcxesABI = require('./../ABIs/BlacxesABI.json');
export const blacxesLockerABI = require('./../ABIs/BlacxesLockerABI.json');
export const accesLockerABI = require('./../ABIs/AccesLockerABI.json');
export const oliNFTABI = require('./../ABIs/OliNFTABI.json');
export const accesDataManagerABI = require('./../ABIs/AccesDataManagerABI.json');
