import { HDNodeWallet, ZeroAddress, isAddress} from 'ethers';
import { accountAddress, wallet} from '../account';
import {contracts} from './../contracts';

let legendaryOliAddress = null;
const legendaryOli = async () => {
    if (!legendaryOliAddress)
        legendaryOliAddress = await contracts.userCreator.legendaryOli();
    return legendaryOliAddress;
}

const accountAddressByName = async (username) => {
    try {
        const userAccountAddress = await contracts.userCreator.getUserAccountAddressByName(username);
        return userAccountAddress && userAccountAddress !== ZeroAddress ? userAccountAddress : null;
    } catch (e) {
        console.error("ERRROR:", e);
        return null;
    }
}

const accountAddressById = async (id) => {
    try {
        const userAccountAddress = await contracts.userCreator.getUserAccountAddressById(id);
        return userAccountAddress && userAccountAddress !== ZeroAddress ? userAccountAddress : null;
    } catch (e) {
        console.error(e);
        return null;
    }
}

const userIdByAccountAddress = async (address) => {
    const user = await contracts.userCreator.getUserIdAndUserName(address);
    return user._userId;
}

const usernameByAccountAddress = async (address) => {
    const user = await contracts.userCreator.getUserIdAndUserName(address);
    return user._userName;
}

const getOwnersWallet = (owner, owners) => {
    owner = owner.toLocaleLowerCase();
    if (owner === accountAddress.toLocaleLowerCase())
        return accountAddress;
    if (owner === wallet.address.toLocaleLowerCase())
        return wallet;
    const rootNode = HDNodeWallet.fromPhrase(wallet.mnemonic.phrase);
    let index = 0;
    let ownersWallet = null;
    do {
        ownersWallet = rootNode.deriveChild(index);
        index++;
        if (index > owners.length) {
            console.error(`Owner ${owner} not found`);
            return null;
        }
    } while (ownersWallet.address.toLocaleLowerCase() !== owner);
    return ownersWallet;
};

const generateNewAddress = async (mnemonic, existingAddresses) => {
    const rootNode = HDNodeWallet.fromPhrase(mnemonic);
    let index = 0;
    let newAddress;
    do {
        const childWallet = rootNode.deriveChild(index);
        newAddress = childWallet.address;
        index++;
    } while (existingAddresses.includes(newAddress) || !isAddress(newAddress));
    console.log(`Generated new address: ${newAddress}`);
    return isAddress(newAddress) ? newAddress : null;
}

export {
    legendaryOli,
    accountAddressByName,
    accountAddressById,
    userIdByAccountAddress,
    usernameByAccountAddress,
    getOwnersWallet,
    generateNewAddress
}